<template>
	<div>
		<div class="card mb-0">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search">
								<div class="search-container-value">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="search"
										placeholder="Codigo o Alias"
										style="width: 200px"
									/>
									<div class="search-btn">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<template v-for="(title, key) in headTable">
								<b-th v-if="title.visible" :key="key">
									<div class="d-flex align-items-center justify-content-center cursor-pointer">
										<p class="mb-0 mr-1 text-nowrap">{{ title.title }}</p>
									</div>
								</b-th>
							</template>
						</b-tr>
					</b-thead>
					<b-tbody>
						<template v-if="corrales.length > 0">
							<b-tr class="text-center" v-for="(item, index) in corrales" :key="index">
								<b-td class="">
									<div
										v-if="!item.edit_corral"
										v-b-tooltip.hover
										title="Ver corral"
										class="d-flex align-items-center justify-content-center cursor-pointer text-primary"
										@click="setCorral(item)"
									>
										<p class="mb-0 font-weight-bolder">{{ item.alias || "Sin alias" }}</p>
									</div>

									<div
										v-else
										class="d-flex align-items-center justify-content-center"
										:style="{ position: 'relative' }"
									>
										<b-form-input
											:style="{ width: '140px' }"
											v-model="item.alias"
											placeholder="Agregar alias"
											@keyup.enter="updateColumnsCorral(item)"
										></b-form-input>
										<FeatherIcon
											@click="updateColumnsCorral(item)"
											icon="SaveIcon"
											size="18"
											class="text-primary cursor-pointer ml-1"
										/>
										<FeatherIcon
											@click="XAliasEdit(item, index)"
											icon="XIcon"
											size="18"
											class="text-danger cursor-pointer ml-1 input-close"
										/>
									</div>
									<div class="mb-0">{{ item.code }}</div>
								</b-td>

								<b-td class="">
									<p class="mb-0 font-weight-bolder text-warning">
										{{
											Number(item.total_chicks) +
											Number(item.chicks_deads) +
											Number(item.total_ejemplares) +
											Number(item.chicks_sick)
										}}
									</p>
								</b-td>
								<b-td class="">
									<div class="d-flex align-items-center justify-content-center font-weight-bolder">
										<span class="m-1">
											{{ item.total_chicks || 0 }}
										</span>
										(
										<span v-b-tooltip.hover.top="'Machos'" class="circlex circlex--male"></span>
										<span class="text-male">
											{{ item.total_chicks_machos || 0 }}
										</span>
										,
										<span v-b-tooltip.hover.top="'Hembras'" class="circlex circlex--female"></span>
										<span class="text-female">
											{{ item.total_chicks_hembras || 0 }}
										</span>
										,
										<span
											v-b-tooltip.hover.top="'Sin asignar'"
											class="circlex circlex--unassigned"
										></span>
										<span style="margin-right: 5px" class="text-unassigned">
											{{ item.total_chicks_sin_asignar || 0 }}
										</span>
										)
									</div>
								</b-td>

								<b-td class="">
									<p class="mb-0 text-danger font-weight-bolder">
										{{ item.chicks_deads || 0 }}
									</p>
								</b-td>
								<b-td class="">
									<div class="mb-0">{{ item.created_by }}</div>

									<div>
										{{ item.start_date | myGlobalDayShort }}
									</div>

									<div class="mb-0">Duración: {{ item.start_date | untilMonthDay }}</div>
								</b-td>

								<b-td v-if="status_id == 2" class="">
									<p class="mb-0">{{ item.updated_by }}</p>

									<p class="mb-0">{{ item.updated_at | myGlobalDayShort }}</p>
								</b-td>

								<b-td v-if="status_id == 3" class="">
									<p class="mb-0">{{ item.updated_by }}</p>

									<p class="mb-0">{{ item.updated_at | myGlobalDayShort }}</p>
								</b-td>

								<b-td v-if="status_id == 1" class="">
									<div class="d-flex justify-content-center">
										<FeatherIcon
											v-b-tooltip.hover
											title="Finalizar corral"
											@click="item.status == 'ACTIVO' && updateCorral(item.id)"
											icon="ApertureIcon"
											size="18"
											class="mr-50"
											:class="[
												item.status == 'ACTIVO'
													? 'text-success cursor-pointer'
													: 'text-secondary',
											]"
										/>
										<FeatherIcon
											v-b-tooltip.hover
											title="Anular corral"
											@click="item.status == 'ACTIVO' && nullifyCorral(item.id)"
											icon="TrashIcon"
											size="18"
											class="mr-50"
											:class="[
												item.status == 'ACTIVO'
													? 'text-danger cursor-pointer'
													: 'text-secondary',
											]"
										/>
										<FeatherIcon
											v-b-tooltip.hover
											title="Retirar pollos"
											@click="removeChicksFromCorral(item)"
											icon="LogOutIcon"
											size="18"
											class="mr-50 cursor-pointer text-danger"
											v-if="!!item.total_chicks"
										/>
									</div>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="corrales.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin corrales encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<ViewChicksByCorral v-if="selectCorral" :corral="selectCorral" @refresh="refreshViewChicks" />
		<ClinicalActionsModal
			:show="clinicalActions.show"
			:info="clinicalActions.info"
			@closing="clinicalActions.show = false"
			@refresh="init"
		/>
		<ParentsByCorral
			:show="parentCorral.show"
			:info="parentCorral.info"
			@closing="parentCorral.show = false"
			@refresh="init"
		/>

		<ClinicalTracking
			:show="ClinicalTrackingCorral.show"
			:info="ClinicalTrackingCorral.info"
			@closing="ClinicalTrackingCorral.show = false"
			@refresh="init"
		/>
	</div>
</template>

<script>
import CreateList from "../modals/CreateCorral.vue"
import ViewChicksByCorral from "./ViewChicksByCorral.vue"
import CorralService from "@/services/encaste/corral.service"
import ClinicalActionsModal from "./ClinicalActionsModal.vue"
import ParentsByCorral from "./ParentsByCorral.vue"
import ClinicalTracking from "./ClinicalTracking.vue"

export default {
	name: "CorralesList",
	components: {
		CreateList,
		ViewChicksByCorral,
		ClinicalActionsModal,
		ParentsByCorral,
		ClinicalTracking,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
		status_id: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			headTable: [
				{ key: "corral", title: "Corral", visible: true },
				{ key: "chicks_ingresados", title: "Ingresados", visible: true },
				{ key: "chicks", title: "Vivos", visible: true },
				{ key: "chicks_dead", title: "Muertos", visible: true },
				{ key: "created_by", title: "Creación", visible: true },
				{ key: "created_by", title: "Finalizado por", visible: this.status_id == 2 },
				{ key: "created_by", title: "Anulado por", visible: this.status_id == 3 },

				{ key: "actions", title: "Acciones", visible: this.status_id == 1 },
			],
			corrales: [],
			start_page: 1,
			to_page: 1,
			total_data: 0,
			current_page: 1,
			perpage: 30,
			search: null,
			selectCorral: null,
			clinicalActions: {
				show: false,
				info: {},
			},
			parentCorral: {
				show: false,
				info: {},
			},
			ClinicalTrackingCorral: {
				show: false,
				info: {},
			},
		}
	},
	created() {
		this.$root.$on("reloader-corrales-list", async () => {
			await this.init()
		})
	},
	destroyed() {
		this.$root.$off("reload-corrales-list")
	},
	async mounted() {
		await this.init()
	},
	methods: {
		async init() {
			this.isPreloading()
			const {
				corrales: { data, from, last_page, to, total },
			} = await CorralService.index({
				page: this.current_page,
				perpage: this.perpage,
				search: this.search,
				orderby: null,
				statusId: this.status_id,
			})
			this.corrales = data.map((corral) => {
				return {
					...corral,
					edit_corral: false,
					copyAlias: corral.alias,
				}
			})
			this.corrales = [...this.corrales]
			this.start_page = from
			this.to_page = to
			this.total_data = total
			this.isPreloading(false)
		},
		setCorral(corral) {
			this.selectCorral = corral
		},
		async refreshViewChicks() {
			this.selectCorral = null
			await this.init()
		},
		async nullifyCorral(corral_id) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()
			const { message } = await CorralService.nullify({
				corral_id,
			})
			this.isPreloading(false)
			this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)
			await this.init()
			this.$emit("refresh-counters")
		},
		async updateCorral(corral_id) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()
			const { message } = await CorralService.update({
				corral_id,
				status: 2,
				chicks: [],
			})
			this.isPreloading(false)
			this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)
			await this.init()
			this.$emit("refresh-counters")
		},
		async updateColumnsCorral(item) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const resp = await CorralService.updateColumnsCorral({
					alias: item.alias,
					comment: item.comment,
					start_date: item.start_date,
					id: item.id,
				})
				this.corrales = this.corrales.map((corral) => {
					return {
						...corral,
						edit_corral: false,
					}
				})
			} catch (error) {
				this.corrales = this.corrales.map((corral) => {
					return {
						...corral,
						edit_corral: item.id == corral.id ? true : false,
					}
				})
			}

			this.isPreloading(false)
		},
		XAliasEdit(item, index) {
			this.corrales[index].edit_corral = false
			this.corrales[index].alias = item.copyAlias
		},
		async dewornCorral() {},
		async applyDeworn(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se realizara una desparacitacion a todos los pollos del corral ${row.alias || row.code}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await CorralService.insertMassiveDeworn({ corral_id: row.id })

			await this.init()
			this.isPreloading(false)
			this.$emit("refresh")
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				"Registro de desparacitacion insertado correctamente"
			)
		},
		async removeChicksFromCorral(corral) {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se retiraran todos los pollos del corral ${corral.alias || corral.code}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await CorralService.removeAllChicks({ corral_id: corral.id })
			this.isPreloading(false)
			this.init()
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				`Se retiraron los pollos del corral ${corral.alias || corral.code}`
			)
		},
	},
	watch: {
		async start_date() {
			await this.init()
		},
		async current_page() {
			await this.init()
		},
	},
}
</script>

<style lang="scss">
.xt-search-input__search {
	width: 100%;
	// margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1400px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.search-container-type {
		display: flex;
		h4 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.search-container-value {
		display: flex;
		flex-direction: column;
		margin-top: 0.5rem;
		@media screen and (min-width: 700px) {
			flex-direction: row;
			// margin-top: .5rem;
			margin-left: 1rem;
		}
		.search-input {
			width: 100% !important;
			@media screen and (min-width: 700px) {
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				width: 50% !important;
			}
		}
		.search-btn {
			margin-top: 0.5rem;
			width: 100% !important;
			@media screen and (min-width: 700px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 50% !important;
			}
		}
	}
}
.input-close {
	position: absolute;
	top: 10px;
	right: 36px;
	bottom: 0;
}

.sticky-table {
	position: sticky;
	top: 40px;
	z-index: 2;
}
.icon-history-clinic {
	background: red;
	border-radius: 50%;
	color: #fff;
	height: 16px;
	width: 16px;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.circlex {
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin: 5px;
}

.circlex--male {
	background-color: #007bff;
}
.text-male {
	color: #007bff;
}
.circlex--female {
	background-color: #e83e8c;
}
.text-female {
	color: #e83e8c;
}
.circlex--unassigned {
	background-color: #6c757d;
}
.text-unassigned {
	color: #6c757d;
}
</style>
